import { Component, OnInit, ViewChild } from '@angular/core';
import {Location} from '@angular/common';
import { DatabaseService } from 'src/app/_services/database.service';
import { ActivatedRoute } from '@angular/router';
import { from } from 'rxjs';
import * as moment from 'moment';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  
})
export class UserAddComponent implements OnInit {
  @ViewChild('select', {static: false}) select : MatSelect
  showLoading:any=false;
  constructor(private _location: Location,public actRoute : ActivatedRoute,public db:DatabaseService) { }
  form:any={};
  // banks:any=[];
  filteredbanks:any=[]
  ngOnInit() {
    this.getRoleData();
    this.getProductDivisionData();
    this.db.getStates();
    this.db.getZone('','','','');
    this.getBankList();
      
    if(this.actRoute.snapshot.params.id){
      this.getDetail(this.actRoute.snapshot.params.id)
    }else{
      this.getRmData(null)
    }
  } 

  getBankList() {
    let reqData = {};
    this.db.presentLoader();
      this.db.postReq(reqData,'bank/list').subscribe(resp=>{
        this.db.dismissLoader();
        if(resp['status'] == 'success'){
          this.filteredbanks = resp['result'].data;
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errDismissLoader();
      })
  }
  
  allSelected=false;
  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
    
    console.log(this.form.working_state);
    
  }
  
  
  
  getDetail(id){
    
    this.db.presentLoader();
    this.showLoading = true;
    this.db.postReq({'user_id':id},'master/sales_user/detail').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.form = resp['data'].detail;
        console.log("====>",this.form)
        this.form.user_id = this.form.id
        // this.form.date_of_joining = moment(this.form.date_of_joining).format('YYYY-MM-DD')
        this.form.mobile = this.form.mobile_no
        this.form.role = parseInt(this.form.role)
        this.form.status = this.form.status.toString();
        
        this.form.working_state = [];
        this.form.product_division = [];
        
        setTimeout(() => {
          console.log(this.form);
          if(resp['data'].assignedState.length){
            resp['data'].assignedState.map(r=>{
              this.form.working_state = [...this.form.working_state,r.state_name];
              // this.form.working_state.push(r.state_name);
            })
            console.log(this.form.working_state);
          }

          if(resp['data'].assignedProductDivision.length){
            resp['data'].assignedProductDivision.map(r=>{
              this.form.product_division = [...this.form.product_division,r.product_division];
              // this.form.working_state.push(r.state_name);
            })
            console.log('product_division -->',this.form.product_division);
          }
          
          if(this.form.role){
            this.getRoleData();
          }
          if(this.form.state_name){
            this.db.getDistricts(this.form.state_name);
          }
          if(this.form.state_name && this.form.district_name){
            this.db.getCity(this.form.state_name,this.form.district_name);
          }
          if(this.form.state_name && this.form.district_name && this.form.city){
            this.db.getArea(this.form.state_name,this.form.district_name,this.form.city);
          }
          this.getRmData(resp['data'].reportingUser.length ? resp['data'].reportingUser[0].assigned_user_id : null)
          this.showLoading = false;
          
        }, 1000 * 2);
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.showLoading = false;
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
    })
    
  }

  changeListener($event,type): void {
      
    var file = $event.target.files[0];
    var reader = new FileReader();
    var image
    reader.onloadend = function () {
      image = reader.result;
    }
    setTimeout(() => {
      let reqData = {base64:image}
      this.db.presentLoader();
      this.db.postReq(reqData,'base64_to_file').subscribe(resp=>{
        this.db.dismissLoader();
        if(resp['status'] == 'success'){
          this.form[type] = resp['response'].fileName;
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errDismissLoader();
      })
    }, 100);
    
    reader.readAsDataURL(file);
    
  }
  
  formSubmitted:any=false;
  userData:any={};
  onSubmit(myForm:any)
  {
    var emailpattern = /^\w.+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/
    
    if(!emailpattern.test(this.form.email)){
      this.db.presentAlert('Error','Please enter a valid email!');
      return;
    }
    var mobilepattern = /^((\\+91-?)|0)?[0-9]{10}$/
    if(!mobilepattern.test(this.form.mobile)){
      this.db.presentAlert('Error','Please enter a valid mobile!');
      return;
    }
    
    // console.log(this.form);return;
    this.formSubmitted = true;
    let reqData = this.form

    this.db.presentLoader();
    this.db.postReq(reqData,this.actRoute.snapshot.params.id ? 'master/sales_user/update' : 'master/sales_user/add').subscribe(resp=>{
      this.formSubmitted = false;
      if(resp['status'] == 'success'){
        this.db.successAlert('Success',resp['message']);
        this.backClicked();
        // myForm.resetForm();
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
    
  }
  rmData:any=[];
  getRmData(assigned_id){
    let reqData = {
      limit : 30000,
      start : 0,
      filter : {}
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'master/reporting_sales_user').subscribe(async resp=>{
      if(resp['status'] == 'success'){
        this.rmData = resp['result'].data;
        if(assigned_id){
          this.form.rmData= await this.rmData.find(r=>r.id == assigned_id)
          // console.log(i,);
        }
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  roleData:any=[];
  // dataNotFound=false
  getRoleData(){
    let reqData = {
      limit : 30000,
      start : 0,
      filter : {
        "for_sales_user": 1
      }
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'master/role_list').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.roleData = resp['result'].data;
        console.log("roledata==>",this.roleData);
        if(this.actRoute.snapshot.params.id){
          this.roleData.map(r=>{
            if(r.id == this.form.role){
              this.form.role = r;
            }
          })
        }
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }

  productDivisionData:any=[];
  // dataNotFound=false
  getProductDivisionData(){
    let reqData = {
      limit : 30000,
      start : 0,
      filter : {}
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'master/product_type').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.productDivisionData = resp['result'].data;
        console.log("productDivisionData==>",this.productDivisionData);
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  
  backClicked() {
    this._location.back();
  }
}
