import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import * as moment from 'moment';
import { DatabaseService } from 'src/app/_services/database.service';

@Component({
  selector: 'app-expense-add',
  templateUrl: './expense-add.component.html'
})
export class ExpenseAddComponent implements OnInit {
  data:any=[];
  autoFocus: boolean = true;
  
  constructor(public db:DatabaseService,private _location: Location) { }
  form:any={
    travel_entitlement:{},
    travel_entitlement_array:[],
    hotel:{},
    hotel_array:[],
    food:{},
    food_array:[],
    local_conveyance:{},
    local_conveyance_array:[],
    food_total:0,
    hotel_total:0,
    local_conveyance_total:0,
    travel_entitlement_total:0,
    total:0,
    fileArr:[],
    misc:{},
    misc_array:[],
    misc_total:0
  };
  ngOnInit() {
    this.getData();
    this.getsalesData();
    this.form.local_conveyance = {}
    this.form.hotel = {}
    this.form.food = {}
    this.form.travel_entitlement = {}
    this.form.misc = {}
    
    this.form.travel_entitlement.departure_date = moment().format('YYYY-MM-DD')
    this.form.travel_entitlement.arrival_date = moment().format('YYYY-MM-DD')
    this.form.hotel.checkin_date = moment().format('YYYY-MM-DD')
    this.form.hotel.checkout_date = moment().format('YYYY-MM-DD')
    this.form.food.date = moment().format('YYYY-MM-DD')
    this.form.local_conveyance.date = moment().format('YYYY-MM-DD')
    this.form.misc.date = moment().format('YYYY-MM-DD')  }
    
    backClicked() {
      this._location.back();
    }
    async getData(){
      
      let reqData = {
        "filter" : {
          "parent_id" : 1
        }
      }
      await this.db.presentLoader();
      this.db.postReq(reqData,'expense/type').subscribe(async resp=>{
        this.db.dismissLoader();
        if(resp['status'] == 'success'){
          this.data = resp['result'].data;
          console.log(this.data.type);
          
          this.form.type=this.data['type'][0].value
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errHandler(err);
      })
    }
    add_to_list(key){
      if(key == 'travel_entitlement'){
        if(!this.form.travel_entitlement.mode_of_travel || !this.form.travel_entitlement.departure_time  || !this.form.travel_entitlement.departure_date || !this.form.travel_entitlement.departure_station || !this.form.travel_entitlement.arrival_date || !this.form.travel_entitlement.arrival_time || !this.form.travel_entitlement.detail_ticket_no || !this.form.travel_entitlement.amount || !this.form.travel_entitlement.travel_distance){
          this.db.presentAlert('Alert','Please fill all the required(*) details!');
          return;
        }
      }
      if(key == 'hotel'){
        if(!this.form.hotel.city || !this.form.hotel.checkin_date || !this.form.hotel.checkout_date || !this.form.hotel.name || !this.form.hotel.amount || !this.form.hotel.remark){
          this.db.presentAlert('Alert','Please fill all the required(*) details!');
          return;
        }
      }
      if(key == 'food'){
        if(!this.form.food.city || !this.form.food.date || !this.form.food.name || !this.form.food.amount || !this.form.food.remark){
          this.db.presentAlert('Alert','Please fill all the required(*) details!');
          return;
        }
      }
      if(key == 'local_conveyance'){
        if(!this.form.local_conveyance.mode_of_travel || !this.form.local_conveyance.date || !this.form.local_conveyance.amount){
          this.db.presentAlert('Alert','Please fill all the required(*) details!');
          return;
        }
      }
      if(key == 'misc'){
        if(!this.form.misc.nature_of_expense || !this.form.misc.date || !this.form.misc.amount || !this.form.misc.remark){
          this.db.presentAlert('Alert','Please fill all the required(*) details!');
          return;
        }
      }
      if(!this.form[key + '_array'].length) this.form[key + '_array'] = [];
      this.form[key + '_array'].push(this.form[key]);
      if(!this.form[key+'_total']) this.form[key+'_total'] = 0
      this.form[key+'_total'] = parseInt(this.form[key+'_total']) + parseInt(this.form[key].amount)
      this.form[key] = {}
      this.form.total = parseInt(this.form['misc_total']) + parseInt(this.form['food_total']) + parseInt(this.form['hotel_total']) + parseInt(this.form['local_conveyance_total']) + parseInt(this.form['travel_entitlement_total']);
      console.log(this.form);
    }
    step = 0;
    async addFile($event){
      console.log($event);
      this.form.image_path_loading = true;
      
      var file = $event.target.files[0];
      var reader = new FileReader();
      var image
      reader.onloadend = function () {
        image = reader.result;
      }
      setTimeout(() => {
        console.log(image);
        let reqData = {base64:image}
        this.db.presentLoader();
        this.db.postReq(reqData,'base64_to_file').subscribe(resp=>{
          this.db.dismissLoader();
          if(resp['status'] == 'success'){
            this.form.image_path = resp['response'].fileName;
            this.form.image_path_loading = false;
            this.form.fileArr = [...this.form.fileArr,resp['response']];
            
          }else{
            if(resp['message']=='Invalid token'){
              this.db.sessionExpire();
              return;
            }
            this.db.presentAlert(resp['status'],resp['message'])
          }
        },err=>{
          this.db.errDismissLoader();
        })
      }, 100);
      
      reader.readAsDataURL(file);
      
    }
    setStep(index: number) {
      this.step = index;
    }
    
    nextStep() {
      this.step++;
    }
    
    prevStep() {
      this.step--;
    }
    remove(i){
      this.form.fileArr.splice(i,1);
    }
    salesData:any=[];
    filteredSalesData:any=[];
    getsalesData(){
      let reqData =  {
        "limit" :100000,
        "start" : 0,
        "filter" : {
          // "cust_type_id" :this.form.value.dr_type.id
        }
      }
      this.db.presentLoader();
      this.db.postReq(reqData,"followup/sales_user").subscribe(async (resp:any) => {
        await this.db.dismissLoader();
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        // this.db.requestSent = true;
        if(resp['status']=='success'){
          this.salesData = resp['result'].data;
          this.filteredSalesData = resp['result'].data;
          
          // if(this.data.id){
          //   let i = this.salesData.findIndex(r=> r.id== this.data.user_id);
          //   this.form.controls['sales_user'].setValue(this.salesData[i]);
          // }
          
        }else{
          this.db.presentAlert(resp['status'],resp['message']);
        }
      },async err => {
        // this.db.errorAlert(err); 
      })  
    }
    onSubmit(data){
      
      if(!this.form.local_conveyance_array.length && !this.form.hotel_array.length && !this.form.food_array.length && !this.form.travel_entitlement_array.length && !this.form.misc_array.length){
        this.db.presentAlert('Alert','Please add expense items');
        return;
      }
      if(!this.form.fileArr.length){
        this.db.presentAlert('Alert','Please add attachments');
        return;
      }
      let reqData = this.form
      console.log(reqData);
      
      this.db.presentLoader();
      this.db.postReq(reqData,'expense/add').subscribe(resp=>{
        this.db.dismissLoader();
        if(resp['status'] == 'success'){
          this.db.successAlert(resp['status'],resp['message']);
          this.backClicked();
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errHandler(err);
        this.db.errDismissLoader();
      })
      
    }
  }
  