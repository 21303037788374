import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, Validators } from '@angular/forms';
import { DatabaseService } from 'src/app/_services/database.service';
import { ActivatedRoute } from '@angular/router';
import { from } from 'rxjs';
import * as moment from 'moment';
import swal from 'sweetalert';
import { CouponDetailModelComponent } from '../coupon-detail/coupon-detail-model.component';

@Component({
  selector: 'app-brand-add',
  templateUrl: './add.component.html'
})
export class CouponAddComponent implements OnInit {

  constructor(private _location: Location, public db: DatabaseService, public actRoute: ActivatedRoute) { }

  ngOnInit() {
    this.getData();
    this.formsInit({});
    this.getProducts();
    this.getOfferData();
  }
  productHandler() {
    console.log("product",this.products)
    this.products.map(r => {
      
      console.log("r",r)
      if (r.id == this.form.value.product_id) {
        this.form.controls['product_name'].setValue(r.product_name);
        this.form.controls['product_code'].setValue(r.product_code);
        this.form.controls['product_category'].setValue(r.product_category);
        // this.form.controls['architect_point'].setValue(r.architect_point);
        this.form.controls['carpenter_point'].setValue(r.carpenter_point);
        // this.form.controls['fabricator_point'].setValue(r.fabricator_point);
        // this.form.controls['distributor_point'].setValue(r.distributor_point);
        // this.form.controls['sales_user_point'].setValue(r.sales_user_point);
        this.form.controls['dealer_point'].setValue(r.dealer_point);
      }
    })
    console.log(this.form.value);

  }
  offerHandler() {
    this.offerData.map(r => {
      if (r.id == this.form.value.offer_id) {
        console.log(r);
        this.form.controls['valid_upto'].setValue(moment(r.end_date).format('YYYY-MM-DD'));
        this.form.controls['offer_title'].setValue(r.title);
        this.form.controls['offer_code'].setValue(r.code);
      }
    })
    console.log(this.form.value);

  }
  products: any = [];
  getProducts() {
    this.db.presentLoader();

    this.db.postReq({}, 'coupon/product_code').subscribe(resp => {
      this.db.dismissLoader();
      if (resp['status'] == 'success') {
        this.products = resp['result']['data']
        console.log("response",resp)
      } else {
        if (resp['message'] == 'Invalid token') {
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'], resp['message'])
      }
    }, err => {
      this.db.errHandler(err);
    })

  }
  form: FormGroup;
  buttonDisabled: any = false
  formsInit(data: any) {
    this.todayDate = moment(new Date()).format('YYYY-MM-DD')

    this.form = this.db.formBuilder.group({
      offer_id: [null],
      offer_title: [null],
      offer_code: [null],
      product_id: [null],
      product_code: [null],
      product_category: [null, []],
      product_name: [null, [Validators.required]],
      description: [null, []],
      no_of_code: [null, [Validators.required]],
      architect_point: [null, []],
      carpenter_point: [null],
      fabricator_point: [null],
      retailer_point: [null, []],
      distributor_point: [null, []],
      sales_user_point: [null, []],
      dealer_point: [null, []],
      valid_upto: [null],
    })
    // this.form.controls['offer_id'].markAsUntouched();
    // this.form.controls['product_id'].markAsUntouched();
    // this.form.controls['product_code'].markAsUntouched();
    // this.form.controls['product_name'].markAsUntouched();
    // this.form.controls['description'].markAsUntouched();
    // this.form.controls['no_of_code'].markAsUntouched();
    // this.form.controls['architect_point'].markAsUntouched();
    // this.form.controls['carpenter_point'].markAsUntouched();
    // this.form.controls['valid_upto'].markAsUntouched();
  }
  todayDate: any

  offerData: any = [];
  filteredOfferData: any = [];
  getOfferData() {

    let reqData = {
      limit: 100000,
      start: 0,
      filter: {
        status: 1
      }
    }
    this.db.presentLoader();
    this.db.postReq(reqData, 'offer/list').subscribe(resp => {

      if (resp['status'] == 'success') {
        this.offerData = resp['result'].data;
        this.filteredOfferData = resp['result'].data;
      } else {
        if (resp['message'] == 'Invalid token') {
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'], resp['message'])
      }
    }, err => {
      this.db.errHandler(err);
    })
  }


  onSubmit() {
    console.log(this.form.value);

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return
    }
    console.log(this.form.value);
    this.db.presentLoader();
    this.buttonDisabled = true;
    this.db.postReq(this.form.value, 'coupon/add').subscribe(resp => {
      this.db.dismissLoader();
      if (resp['status'] == 'success') {
        this.db.successAlert(resp['status'], resp['message']);
        // this.backClicked();
        this.getData();
        this.formsInit({});
      } else {
        if (resp['message'] == 'Invalid token') {
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'], resp['message'])
      }
    }, err => {
      this.db.errDismissLoader();
    })

  }

  backClicked() {
    this._location.back();
  }
  tabActiveType: any = {};

  pageNo: any = 1;
  tempPageNo = 1;
  totalPages: any
  data: any = [];
  start: any = 0;
  limit: any = 50;
  totalLength: any;
  filter: any = {};
  getData() {

    let reqData = {
      limit: this.limit,
      start: this.start,
      filter: this.filter
    }
    this.db.presentLoader();
    this.db.postReq(reqData, 'coupon/grouped_list').subscribe(resp => {
      if (resp['status'] == 'success') {
        this.data = resp['result'].data;
        console.log(this.data);

        this.totalLength = resp['result'].totalCount;
        this.totalPages = Math.ceil(this.totalLength / this.limit);
      } else {
        if (resp['message'] == 'Invalid token') {
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'], resp['message'])
      }
    }, err => {

    })
  }
  pagination(action) {

    if (action == 'pageNo') {
      if (this.pageNo > 0 && this.pageNo <= this.totalPages) {
        this.start = this.limit * (this.pageNo - 1)
      } else {
        this.pageNo = Math.ceil(this.totalLength / this.data.length);
      }
    }
    else if (action == 'next') {

      if (this.totalLength == (this.start + this.data.length)) return;

      this.start = this.start + this.limit
      this.pageNo++;
    } else {
      if (this.pageNo == 1) return
      this.start = this.start + this.limit
      this.pageNo--;
    }
    this.getData();
    this.tempPageNo = this.pageNo
  }

  downloadExcel() {
    let reqData = {
      filter: this.filter
    }
    this.db.presentLoader();
    this.db.postReq(reqData, 'coupon/grouped_list/excel').subscribe(resp => {
      if (resp['status'] == 'success') {
        this.db.exportAsExcelFile(resp['result'].data, resp['result'].file_name ? resp['result'].file_name : 'exported_data');
      } else {
        if (resp['message'] == 'Invalid token') {
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'], resp['message'])
      }
    }, err => {

    })

  }



  downloadExcelCoupon(row: any) {
    // console.log('row', row);
    let reqData = {
      filter: {
        coupon_ref: row.coupon_ref
      }
    }

    this.db.presentLoader();
    this.db.postReq(reqData, 'coupon/list/excel').subscribe(resp => {
      if (resp['status'] == 'success') {
        this.db.exportAsExcelFile(resp['result'].data, resp['result'].file_name ? resp['result'].file_name : 'exported_data');
      } else {
        if (resp['message'] == 'Invalid token') {
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'], resp['message'])
      }
    }, err => {

    })

  }
  delete(data) {
    swal({
      title: "Are you sure?",
      text: "You want to delete this!",
      icon: "warning",
      buttons: ["Cancel", "Confirm"],
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          this.db.presentLoader();

          this.db.postReq(data, 'coupon/grouped_list/delete').subscribe(resp => {
            if (resp['status'] == 'success') {
              this.getData();
              swal("Deleted", {
                icon: "success",
              });
            } else {
              if (resp['message'] == 'Invalid token') {
                this.db.sessionExpire();
                return;
              }
              this.db.presentAlert(resp['status'], resp['message'])
            }
          })

        } else {
          swal("Your data is safe!");
        }
      });
  }
  openDetail(row): void {
    console.log(row);

    const dialogRef = this.db.matdialog.open(CouponDetailModelComponent, {
      width: '768px',
      autoFocus: false,
      data: row
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
}
