import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { FormGroup, Validators } from '@angular/forms';
import { DatabaseService } from 'src/app/_services/database.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html'
})
export class BannerAddComponent implements OnInit {
  
  constructor(private _location: Location,public db:DatabaseService,public actRoute:ActivatedRoute) { }
  
  ngOnInit() {
    this.db.getStates()
    if(this.actRoute.snapshot.params.id){
      this.getDetail(this.actRoute.snapshot.params.id)
    }    else{
      this.formsInit({});
    }
  }
  getDetail(id){
    this.db.presentLoader();
    
    this.db.postReq({'banner_id':id},'master/banner_detail').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.formsInit(resp['data']['detail']);
        
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.dismissLoader();
      this.db.errHandler(err);
    })
    
  }
  form: FormGroup;

  // fabricator_banner_checked: boolean = false;
  // carpenter_banner_checked: boolean = false;
  formsInit(data:any) {
    this.form = this.db.formBuilder.group({
      image_path: [data.image_path ? data.image_path : null , [Validators.required]],
      image: [data.image ? data.image : null , []],
      image_path_loading:[false],
      name: [data.name ? data.name : null , [Validators.required]],
      ordering: [data.ordering ? data.ordering.toString() : null , [Validators.required]],
      // fabricator_banner :[this.actRoute.snapshot.params.id ? data.fabricator_banner == 1 ? true : false : false ,[Validators.required]],
      // carpenter_banner :[this.actRoute.snapshot.params.id ? data.carpenter_banner == 1 ? true : false : false ,[Validators.required]],
      status: [data.status ? data.status.toString() :"1" , [Validators.required]],
      banner_id: [data.id ? data.id : null],
    })
    // this.fabricator_banner_checked = this.form.value.fabricator_banner;
    // this.carpenter_banner_checked = this.form.value.carpenter_banner;
  }
  onSubmit(){
    if(this.form.invalid){
      this.form.controls['name'].markAsTouched();
      this.form.controls['status'].markAsTouched();
      this.form.controls['ordering'].markAsTouched();
      this.form.controls['image_path'].markAsTouched();
      return
    }
    console.log(this.form.value);
    this.db.presentLoader();
    this.db.postReq(this.form.value,this.actRoute.snapshot.params.id ? 'master/update_banner' : 'master/add_banner').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.db.successAlert(resp['status'],resp['message']);
        this.backClicked();
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errDismissLoader();
      this.db.errHandler(err);
    })
    
  }
  changeListener($event): void {
    console.log($event);
    this.form.controls['image_path_loading'].setValue(true);
    
    var file = $event.target.files[0];
    var reader = new FileReader();
    var image
    reader.onloadend = function () {
      image = reader.result;
    }
    setTimeout(() => {
      console.log(image);
      let reqData = {base64:image}
      this.db.presentLoader();
      this.db.postReq(reqData,'base64_to_file').subscribe(resp=>{
        this.db.dismissLoader();
        if(resp['status'] == 'success'){
         this.form.controls['image_path'].setValue(resp['response'].fileName);
         this.form.controls['image_path_loading'].setValue(false);
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errDismissLoader();
      })
    }, 100);
    
    reader.readAsDataURL(file);
    
  }
  backClicked() {
    this._location.back();
  }
  
}
